<script>
import Gen from "@helper/Gen.js";
import env from "../../env.js";

export default {
  props: ["name", "value", "config", "autogrowconfig"],
  computed: {
    page() {
      return this.$root.page;
    },
  },
  async mounted() {
    global.CKEDITOR_BASEPATH = env.baseUrl + "/plugins/ckeditor/";
    await Gen.loadScript("/plugins/ckeditor/ckeditor.js");

    this.init();
  },
  methods: {
    init() {
      if (this.autogrowconfig == "off") {
        this.ck = global.CKEDITOR.replace(
          this.name,
          Object.assign(
            {
              height: "200px",
              extraPlugins: [
                "pastecode" /* ,'imagebrowser' */,
                "wordcount",
                "youtube",
                "colorbutton",
                "panelbutton",
              ],
              allowedContent: true,
              embed_provider:
                "//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}",
              // embed_provider: '//noembed.com/embed?url={url}&callback={callback}',
              filebrowserUploadUrl:
                env.baseUrl + "/ajax/upload?pageType=editor",
            },
            this.config || {}
          )
        );
      } else {
        this.ck = global.CKEDITOR.replace(
          this.name,
          Object.assign(
            {
              height: "200px",
              extraPlugins: [
                "autogrow",
                "pastecode" /* ,'imagebrowser' */,
                "wordcount",
                "youtube",
                "colorbutton",
                "panelbutton",
              ],
              allowedContent: true,
              embed_provider:
                "//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}",
              // embed_provider: '//noembed.com/embed?url={url}&callback={callback}',
              filebrowserUploadUrl:
                env.baseUrl + "/ajax/upload?pageType=editor",
            },
            this.config || {}
          )
        );
      }
      this.ck.on("change", () => {
        this.$emit("input", this.ck.getData());
        this.onEdit = true;
        this.$el.value = this.ck.getData();
        Gen.delay(() => {
          this.onEdit = false;
          $(this.$el).valid();
        }, 300);
      });
      this.ck.on("instanceReady", () => {
        setTimeout(() => {
          if (this.value) {
            this.$el.value = this.value;
            this.ck.setData(this.value);
          }
        }, 300);
      });
    },
  },
  watch: {
    value: function(v) {
      if (!v) this.ck.setData("");
      if (!this.onEdit) {
        if (this.ck) this.ck.setData(v);
      }
    },
  },
  destroyed: function() {
    $("#cke_" + this.name).remove();
  },
};
</script>

<template>
  <textarea :name="name" :id="name"></textarea>
</template>
